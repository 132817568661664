var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main user-layout-register" },
    [
      _vm._m(0),
      _c(
        "a-form",
        {
          ref: "formRegister",
          attrs: {
            autoFormCreate: (form) => {
              this.form = form
            },
            id: "formRegister",
          },
        },
        [
          _c(
            "a-form-item",
            {
              attrs: {
                fieldDecoratorId: "username",
                fieldDecoratorOptions: {
                  rules: [
                    { required: false },
                    { validator: this.checkUsername },
                  ],
                },
              },
            },
            [
              _c("a-input", {
                attrs: {
                  size: "large",
                  type: "text",
                  autocomplete: "false",
                  placeholder: "请输入用户名",
                },
              }),
            ],
            1
          ),
          _c(
            "a-popover",
            {
              attrs: {
                placement: "rightTop",
                trigger: "click",
                visible: _vm.state.passwordLevelChecked,
              },
            },
            [
              _c("template", { slot: "content" }, [
                _c(
                  "div",
                  { style: { width: "240px" } },
                  [
                    _c(
                      "div",
                      { class: ["user-register", _vm.passwordLevelClass] },
                      [
                        _vm._v("强度："),
                        _c("span", [_vm._v(_vm._s(_vm.passwordLevelName))]),
                      ]
                    ),
                    _c("a-progress", {
                      attrs: {
                        percent: _vm.state.percent,
                        showInfo: false,
                        strokeColor: _vm.passwordLevelColor,
                      },
                    }),
                    _c("div", { staticStyle: { "margin-top": "10px" } }, [
                      _c("span", [
                        _vm._v(
                          "请至少输入 8 个字符。请不要使用容易被猜到的密码。"
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "a-form-item",
                {
                  attrs: {
                    fieldDecoratorId: "password",
                    fieldDecoratorOptions: {
                      rules: [
                        { required: false },
                        { validator: this.handlePasswordLevel },
                      ],
                    },
                  },
                },
                [
                  _c("a-input", {
                    attrs: {
                      size: "large",
                      type: "password",
                      autocomplete: "false",
                      placeholder: "至少8位密码，区分大小写",
                    },
                    on: { click: _vm.handlePasswordInputClick },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                fieldDecoratorId: "password2",
                fieldDecoratorOptions: {
                  rules: [
                    { required: false },
                    { validator: this.handlePasswordCheck },
                  ],
                },
              },
            },
            [
              _c("a-input", {
                attrs: {
                  size: "large",
                  type: "password",
                  autocomplete: "false",
                  placeholder: "确认密码",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                fieldDecoratorId: "mobile",
                fieldDecoratorOptions: {
                  rules: [
                    { required: false },
                    { validator: this.handlePhoneCheck },
                  ],
                },
              },
            },
            [
              _c(
                "a-input",
                { attrs: { size: "large", placeholder: "11 位手机号" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        slot: "addonBefore",
                        size: "large",
                        defaultValue: "+86",
                      },
                      slot: "addonBefore",
                    },
                    [
                      _c("a-select-option", { attrs: { value: "+86" } }, [
                        _vm._v("+86"),
                      ]),
                      _c("a-select-option", { attrs: { value: "+87" } }, [
                        _vm._v("+87"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "a-col",
                { staticClass: "gutter-row", attrs: { span: 16 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        fieldDecoratorId: "captcha",
                        fieldDecoratorOptions: {
                          rules: [
                            { required: false },
                            { validator: this.handleCaptchaCheck },
                          ],
                        },
                      },
                    },
                    [
                      _c(
                        "a-input",
                        {
                          attrs: {
                            size: "large",
                            type: "text",
                            placeholder: "验证码",
                          },
                        },
                        [
                          _c("a-icon", {
                            style: { color: "rgba(0,0,0,.25)" },
                            attrs: { slot: "prefix", type: "mail" },
                            slot: "prefix",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { staticClass: "gutter-row", attrs: { span: 8 } },
                [
                  _c("a-button", {
                    staticClass: "getCaptcha",
                    attrs: { size: "large", disabled: _vm.state.smsSendBtn },
                    domProps: {
                      textContent: _vm._s(
                        (!_vm.state.smsSendBtn && "获取验证码") ||
                          _vm.state.time + " s"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.getCaptcha.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-button",
                {
                  staticClass: "register-button",
                  attrs: {
                    size: "large",
                    type: "primary",
                    htmlType: "submit",
                    loading: _vm.registerBtn,
                    disabled: _vm.registerBtn,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.handleSubmit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("注册\n        ")]
              ),
              _c(
                "router-link",
                { staticClass: "login", attrs: { to: { name: "login" } } },
                [_vm._v("使用已有账户登录")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", [_c("span", [_vm._v("注册")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }