import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=56b00485&scoped=true&"
import script from "./Register.vue?vue&type=script&lang=js&"
export * from "./Register.vue?vue&type=script&lang=js&"
import style0 from "./Register.vue?vue&type=style&index=0&id=56b00485&lang=less&"
import style1 from "./Register.vue?vue&type=style&index=1&id=56b00485&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b00485",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jobs/management-system-frontend/workspace/ant-design-vue-jeecg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56b00485')) {
      api.createRecord('56b00485', component.options)
    } else {
      api.reload('56b00485', component.options)
    }
    module.hot.accept("./Register.vue?vue&type=template&id=56b00485&scoped=true&", function () {
      api.rerender('56b00485', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/register/Register.vue"
export default component.exports